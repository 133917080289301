(function($){
	// キャンペーンパラメータ引継ぎ用
	addURLParameter();

	function addURLParameter(){
		// ルートコード追加
		if(location.pathname.split('/')[1] === 'ladies') {
			rootQuery();
		}

		// 別ドメインにキャンペーンパラメータ付与
		var brandUrl = [
			'www.aderans.co.jp',
			'www.aderans.jp',
			'www.aderans.com'
		];
		var hostname = location.hostname;
		var idxChk = brandUrl.indexOf(hostname);

		if(idxChk > -1) brandUrl.splice(idxChk, 1);

		for(var i=0; i < brandUrl.length; i++) {
			var anchor = document.getElementsByTagName('a');
			for(var j=0; j < anchor.length; j++) {
				var regExp = new RegExp(brandUrl[i]);
				var href = anchor[j].href;

				if((href.match(regExp) || []).length > 0) {
					var query = (href.match(/\?/) || []).length > 0 ? '&' : '?';

					anchor[j].href += query+'utm_source='+hostname+'&utm_medium=referrer&brandFlag=true';
				}
			}
		}
		// キャンペーンパラメータ引き継ぎ
		if(typeof $.cookie === 'undefined') {
			aync('/common/js/jquery.cookie.js', addUtmCookies);
		} else {
			addUtmCookies();
		}

		function addUtmCookies(){
			if(getQuery()['utm_source'] && getQuery()['utm_medium']) {
				var cookieDate = new Date();
				cookieDate.setTime(cookieDate.getTime() + (60 * 60 * 1000)); // 1時間

				$.cookie('aderans_utm_source', getQuery()['utm_source'], { path:'/', expires:cookieDate });
				$.cookie('aderans_utm_medium', getQuery()['utm_medium'], { path:'/', expires:cookieDate });

				if(getQuery()['utm_campaign']) {
					$.cookie('aderans_utm_campaign', getQuery()['utm_campaign'], { path:'/', expires:cookieDate });
				}
				if(getQuery()['utm_content']) {
					$.cookie('aderans_utm_content', getQuery()['utm_content'], { path:'/', expires:cookieDate });
				}
			}
			if($.cookie('aderans_utm_source') && $.cookie('aderans_utm_medium')) {
				var cookie = {
					source: $.cookie('aderans_utm_source'),
					medium: $.cookie('aderans_utm_medium'),
					campaign: typeof $.cookie('aderans_utm_campaign') !== 'undefined' ? $.cookie('aderans_utm_campaign') : null,
					content: typeof $.cookie('aderans_utm_content') !== 'undefined' ? $.cookie('aderans_utm_content') : null
				};

				$('body').find('a[href]').each(function(){
					run(this, cookie);
				});
			}

			function run(_that, _cookie){
				var $this = $(_that);
				var $anchor = $this.attr('href');
				var hostPathName = '',
					queryStrings = '',
					hash = '',
					url = '';

				var brandFlag = ($anchor.match(/brandFlag=true/) || []).length;

				if(brandFlag === 0 && $anchor.match(/e-check\.jp/g)) {
					hostPathName = $anchor.split('?')[0];
					var source = _cookie.source !== null ? '&utm_source='+_cookie['source'] : '';
					var medium = _cookie.medium !== null ? '&utm_medium='+_cookie['medium'] : '';
					var campaign = _cookie.campaign !== null ? '&utm_campaign='+_cookie['campaign'] : '';
					var content = _cookie.content !== null ? '&utm_content='+_cookie['content'] : '';

					if($anchor.split('?').length > 1) {
						queryStrings = $anchor.split('?')[1];

						if($anchor.match(/#/)) {
							queryStrings = $anchor.split('?')[1].split('#')[0];
							hash = '#'+$anchor.split('#')[1];
						}
						url = hostPathName+'?'+queryStrings+source+medium+campaign+content+hash;
					} else {
						if($anchor.match(/#/)) {
							hostPathName = $anchor.split('#')[0];
							hash = '#'+$anchor.split('#')[1];
						}
						source = '?'+source.substr(1);
						url = hostPathName+source+medium+campaign+content+hash;
					}
					if((url.match(/undefined/) || []).length === 0) {
						$this.attr('href', url);
					} else {
						// urlパラメータにundefinedが含まれていたらエラーをGAに送信する
						if(ga) ga('send', 'CampaignParameterError', $anchor, location.href);
					}
				}
			}
		}
	}
	function aync(_url, _callback){
		var doc = document,
			tag = 'script',
			item = doc.createElement(tag),
			firstScript = doc.getElementsByTagName(tag)[0];
		item.type = 'text/javascript';
		item.src = _url;

		if(_callback) {
			item.addEventListener('load', function(){
				_callback();
			}, false);
		}
		firstScript.parentNode.insertBefore(item, firstScript);
	}
	function getQuery(){
		var vars = [], hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for(var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	}
	// ルートコード追加
	function rootQuery(){
		$('a[href]').each(function(){
			var href = this.href;
			var params = href.split('?');
			var echeckUrl = 'e-check.jp';
			var codeUrl = 'route_cd';
			var domainChk = href.indexOf(echeckUrl);
			var codeChk = href.indexOf(codeUrl);

			var hostPathName = '',
				queryStrings = '',
				url = '';

			if(domainChk > 0 && codeChk < 0) {
				if(params.length > 1) {
					hostPathName = params[0];
					queryStrings = params[1];
					url = hostPathName+'?route_cd=LJ&'+queryStrings;
				} else {
					url = href+'?route_cd=LJ';
				}
				this.href = url;
			}
		});
	}
	// dadcid引継ぎ
	console.log("aaaaa");
	dadcidQuery();
	function dadcidQuery(){
		$('a[href]').each(function(){
			var href = this.href;
			var echeckUrl = 'e-check.jp';
			var domainChk = href.indexOf(echeckUrl);

			if(domainChk > 0) {
				var urlParam = location.search.substring(1);
				if(urlParam) {
					let param = urlParam.split('&');
					let paramArray = [];
					for(let i = 0; i < param.length; i++) {
						let paramItem = param[i].split('=');
						paramArray[paramItem[0]] = paramItem[1];
					}
					if(paramArray.dadcid) {
						var dadcidParam = 'dadcid=' + paramArray.dadcid;
						var url = this.href;
						if(this.href.indexOf('?') != -1) {
							this.href = url + '&' + dadcidParam;
						} else {
							this.href = url + '?' + dadcidParam;
						}
						console.log(this.href);
					}
				}
			}
		});
	}
})(window.jQuery);