window.console || (window.console = {
	log: function(){}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function($){
	const siteName = 'aderans';
	const a = 'is-active';
	const f = 'is-fixed';
	const v = 'is-visible';
	const slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision(){
			var regExp = new RegExp(`(test\.${siteName}\.co\.jp|copre\.jp|localhost|192\.168\.0\.|192\.168\.11\.|192\.168\.1\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading(){
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for(i=0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if(ajaxLen > 0) {
				for(i=0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i]+'.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for(i=0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for(j=0; j < position.length; j++) {
							if(position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.'+position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError(_errorThrown){
			if(window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery(){
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle(){
			var $switch = $('.js-toggle');

			$switch.on('click', function(){
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
			//spのみ
			if(FUNCTIONS.va.window.width < 768) {
				var $switch = $('.js-sp-toggle');

				$switch.on('click', function(){
					$(this).toggleClass(a);
					$(this).next('.js-sp-toggleBlock').stop().slideToggle(slideSpeed);
				});
			}
		},
		easeScroll(){
			$('a[rel="scroll"]').on('click', (event) => {
				if($('.js-header-menu-content').hasClass(a)) {
					$('.js-header-menu-content').removeClass(a);
					$('.js-header-menu-content').removeClass(v);
					$('.header-menu').removeClass(a);
					$('body').removeClass(f);
				}
				var speed = 1200;
				if(/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed){
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).get( 0 ).offsetTop;
				var zoompos = pos *0.8;
				let userAgent = window.navigator.userAgent.toLowerCase();
				if(userAgent.indexOf('firefox') != -1) {
					if(hash !== '#pagetop') {
						if($(window).width() < 1024) {
	                        pos -= 60;
	                        $('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
	                    } else {
	                        pos -= 70;
	                        $('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
	                    }
					}
				} else {
					if(hash !== '#pagetop') {
						if($(window).width() < 1024) {
	                        pos -= 60;
	                        $('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
	                    } else if($(window).width() < 1680) {
	                        zoompos -= 70;
	                        $('html,body').stop().animate({ scrollTop: zoompos }, _speed, 'easeOutExpo');
	                    } else {
	                        pos -= 70;
	                        $('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
	                    }
					}
				}
			}
		},
		pageTop(){
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function(e){
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				// var footerPos = $footer.get( 0 ).offsetTop + $footer.height();
				var thisScroll = $this.scrollTop();
				// var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if(thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu() {
			let $header = $('.header-menu');
			let $menu = $('.js-header-menu');
			let $menucontent = $('.js-header-menu-content');
			let $menuclosebtn = $('.header-content-btmClose');
			var scrollTopNow;

			//pc・sp共通ハンバーガーメニュー
			$menu.on('click', function(){
				scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
				$menucontent.toggleClass(a);
				$header.toggleClass(a);
				$('body').toggleClass(f);
				$menu.css('pointer-events','none');
				setTimeout(function(){
                    $menucontent.toggleClass(v);
                    $menu.css('pointer-events','auto');
                }, 100);

			});
			$menuclosebtn.on('click', function(){
				scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
				$menucontent.removeClass(a);
				$menucontent.removeClass(v);
				$header.removeClass(a);
				$('body').removeClass(f);
			});
			$('a[href^="/mens/first/price/#"]').on('click', function(){
				setTimeout("location.reload()",1000);
			});
		},
		headerScroll() {
			$('.header').addClass(v);
			$('.header-menu-nav').addClass(v);
			$(window).scroll(function () {
				var headerHeight = $('.header').innerHeight();
				var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				if(scrollTop > headerHeight) {
					$('.header').addClass(f);
				}else if($('.header').hasClass(f)){
					$('.header').removeClass(f);
				}
			});
		},
		headerCurrent() {
            if ($('.header-menu-nav-item').length) {
			    $('.header-menu-nav-item').each((idx, val) => {
			        // 見ているページが/start/price/だった場合
			        if (location.pathname.replace('index.html', '') === '/mens/start/price/') {
			            // ページurlとリンクurlが完全一致した場合
			            if (location.pathname.replace('index.html', '') === $(val).attr('href').replace('index.html', '')) {
			                $(val).addClass(a);
			            }
			        } 
			        // それ以外のページの場合、見ているページのpassがリンクurlに含まれていたら
			        else if (location.pathname.indexOf($(val).attr('href').replace('index.html', '')) != -1) {
			            $(val).addClass(a);
			        }
			    });
			}
        },
        headerFixedBanner() {
	        $('.header').addClass('__nofixed');
			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var thisScroll = $this.scrollTop();
				if(thisScroll > '150') {
					$('.footer-fixedBnr').addClass('is-fixed');
				} else {
					$('.footer-fixedBnr').removeClass('is-fixed');
				}
			});
		},
		yahooSearch() {
			//#srchBox
			var sb = document.getElementById('srchBox');
			if (sb && sb.className == 'watermark') {
				var si = document.getElementById('srchInput');
				var f = function() { si.className = 'nomark'; };
				var b = function() {
					if (si.value == '') {
						si.className = '';
					}
				};
				si.onfocus = f;
				si.onblur = b;
				if (!/[&?]p=[^&]/.test(location.search)) {
					b();
				} else {
					f();
				}
			}
		},
		setImgAttrWidthHeight() {
			if($('.js-setImgAttrWidthHeight').length) {
				const getImg = (_src) => {
					return new Promise((resolve, reject) => {
						const image = new Image();
						image.src = _src;
						image.onload = () => {
							resolve(image);
						}
						image.onerror = (error) => {
							reject(error);
						}
					});
				};
				const imgs = document.getElementsByTagName('img');
				for (const img of imgs) {
					const src = img.getAttribute('src');
					getImg(src).then((res) => {
						if (!img.hasAttribute('width')) {
							img.setAttribute('width', res.width);
						}
						if (!img.hasAttribute('height')) {
							img.setAttribute('height', res.height);
						}
					})
						.catch((error) => {
							console.log(error);
						});
				}
			}
		},
		setslider() {
			if($('.js-startSlider').length) {
				const slider = $('.js-startSlider');
				slider.on('init', function(slick){
					slider.find('.experience-voice-report-ttl').matchHeight();
				  	// console.log('スライダーが初期化された時のイベント');
				});
				slider.slick({
					arrows: true,
					slidesToShow: 2,
					prevArrow: '<button class="slide-arrow prev-arrow"><svg class=""><use xlink:href="/mens/common/svg/sprite.svg#arrow_left"></use></svg></button>',
					nextArrow: '<button class="slide-arrow next-arrow"><svg class=""><use xlink:href="/mens/common/svg/sprite.svg#arrow_right"></use></svg></button>',
					responsive: [
						{
							breakpoint: 1025,
							settings: {
								slidesToShow: 1,
							}
						}
					]
				});
				slider.on('afterChange', function(slick, currentSlide){
					slider.find('.experience-voice-report-ttl').matchHeight();
				  	// console.log('スライド切り替え後のイベント');
				});
			}
		},
		beforeAfterImg() {
			const $sliderRange = $('.js-sliderRange')
			if($sliderRange.length) {
				$sliderRange.on("input",function(){
					$(this).siblings(".js-boxBefore").css( 'width' , $(this).val() + '%');
				})
				$sliderRange.on("change",function(){
					$(this).siblings(".js-boxBefore").css( 'width' , $(this).val() + '%');
				})
			}
		},
		faqAccordion() {
			$(".js-accordion-title").on("click", function() {
				$(this).next().slideToggle();
				$(this).toggleClass(a);
			});
		},
		seeMoreAccordion() {
			const listItems = $(".experience-voice-report-in li").length;
		  	$(".experience-voice-report-in").each(function () {
		    	let num = 3,
		      	closeNum = num - 1;
				    $(this).find(".js-btn-more").show();
				    $(this).find(".js-btn-close").hide();
			    	$(this)
			    		.find("li:not(:lt(" + num + "))")
			    		.hide();
		    		$(".js-btn-more").click(function () {
			      		num += 10;
			      		$(this)
			    			.parent()
			        		.find("li:lt(" + num + ")")
			        		.slideDown();
			     	if (listItems <= num) {
			        $(".js-btn-more").hide();
			        $(".js-btn-close").show();
			        $(".js-btn-close").click(function () {
			          $(this)
			            .parent()
			            .find("li:gt(" + closeNum + ")")
			            .slideUp();
							$(this).hide();
							$(".js-btn-more").show();
						});
					}
				});
			});
		},
		scrolltable() {
			if($('.js-scrolltable').length) {
				new ScrollHint('.js-scrolltable', {
					i18n: {
						scrollable: 'スクロールできます'
					}
				});
			}
		},
		androidAddClass() {
			if (navigator.userAgent.indexOf('Android') > 0) { 
				let body = document.getElementsByTagName('body')[0]; 
				body.classList.add('Android'); 
			}
		},
		loadDelayScript(){
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.headerScroll();
			_this.headerFixedBanner();
			_this.setImgAttrWidthHeight();
			_this.setslider();
			_this.beforeAfterImg();
			_this.faqAccordion();
			_this.seeMoreAccordion();
			_this.scrolltable();
			_this.headerCurrent();
			_this.androidAddClass();
			// _this.yahooSearch();
		}
	};

	$(() => FUNCTIONS.loadDelayScript());
})(window.jQuery);
 // easeScrollHash()
 if(location.hash) {
	var hash = location.hash;
	var map = location.pathname.split('/')[1];
	$(window).on('load', function(){
		$('html, body').css('transition', 'all 0.3s ease');
		$('html, body').css('opacity', '0');
		$('html, body').stop().animate({ scrollTop: 0 }, 0, 'easeOutExpo');
		setTimeout(function(){
			$('html, body').css('opacity', '1');

			var _hash = '#' + hash.split('#')[1];
			var pos = $(_hash).get( 0 ).offsetTop;
			var zoompos = pos *0.8;
			let userAgent = window.navigator.userAgent.toLowerCase();
			if(userAgent.indexOf('firefox') != -1) {
				if(hash !== '#pagetop') {
					if($(window).width() < 1024) {
						pos -= 60;
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					} else {
						pos -= 70;
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}
				}
			} else {
				if(hash !== '#pagetop') {
					if($(window).width() < 1024) {
						pos -= 60;
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					} else if($(window).width() < 1680) {
	                    zoompos -= 70;
	                    $('html, body').stop().animate({ scrollTop: zoompos }, 1200, 'easeOutExpo');
	                } else {
						pos -= 70;
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					}
				}
			}
		}, 300);
	});
}
$(window).on('load', function(){
	// matchHeightImg
	$('.js-matchHeightImg').matchHeight();
});