(function($, FUNCTIONS, cssua){
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	FUNCTIONS = {
		animation(){
			if($('.js-inview-left').length) {
				$('.js-inview-left').on('inview', function(event, isInView) {
					if (isInView) {
						$(this).next('.js-inview-left-move').addClass('is-active');
					}
				});
			}
			if($('.js-inview-bottom').length) {
				$('.js-inview-bottom').on('inview', function(event, isInView) {
					$(this).addClass('is-active');
				});
			}
			if($('.js-inview-flip').length) {
				$('.js-inview-flip').on('inview', function(event, isInView) {
					$(this).addClass('is-active');
				});
			}
			if($('.js-inview-lp-left').length) {
				$('.js-inview-lp-left').on('inview', function(event, isInView) {
					$(this).addClass('is-active');
				});
			}
		},
		modal() {
			var scrollTopNow;
			$('.js-cashback-modal-btn').on('click', function(event){
				var title = $(this).data('title');
				var imgs = $(this).data('imgs');
				var lead = $(this).data('lead');
				var link = $(this).data('link');
				$('.first-price-modal-overlay').addClass(a);
				$('.first-price-modal-content').html(
					'<div>' +
					'<img src="/mens/rn-images/campaign/22cashback/'+ imgs +'_pc.png" class="width-u-100" data-view="pc tablet">' +
					'<img src="/mens/rn-images/campaign/22cashback/'+ imgs +'_sp.png" class="width-u-100" data-view="sp">' +
					'</div>'
				);
				scrollTopNow = $(window).scrollTop();
				$('body').addClass(f);
				$( '.container' ).css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				event.preventDefault();
			});
			$('.js-first-price-modal-btn').on('click', function(event){
				var title = $(this).data('title');
				var imgs = $(this).data('imgs');
				var lead = $(this).data('lead');
				var link = $(this).data('link');
				$('.first-price-modal-overlay').addClass(a);
				$('.first-price-modal-content').html(
					'<div class="p40">' +
					'<h3 class="first-price-modal-title ff_mincho">'+ title +'</h3>' +
					'<img src="/mens/rn-images/first/price/modal/'+ imgs +'_pc.jpg" class="width-u-100" data-view="pc tablet">' +
					'<img src="/mens/rn-images/first/price/modal/'+ imgs +'_sp.jpg" class="width-u-100" data-view="sp">' +
					'</div>'
				);
				scrollTopNow = $(window).scrollTop();
				$('body').addClass(f);
				$( '.container' ).css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				event.preventDefault();
			});
			$('.first-price-modal-closeBtn').on('click', function(event){
				$('.first-price-modal-content').html('');
				$('.first-price-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
			$('.first-price-modal-back').on('click', function(event){
				$('.first-price-modal-content').html('');
				$('.first-price-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
			$('.mod-modal-btn').on('click', function(event){
				$('.mod-modal-overlay').addClass(a);
				let img = $(this).find('img').attr('src');
				$('.mod-modal-content').html(
					'<div class="p40">' +
					'<img src="'+ img +'" class="width-u-100">' +
					'</div>'
				);
				scrollTopNow = $(window).scrollTop();
				$('body').addClass(f);
				$( '.container' ).css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				event.preventDefault();
			});
			$('.mod-modal-closeBtn').on('click', function(event){
				$('.mod-modal-content').html('');
				$('.mod-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
			$('.mod-modal-back').on('click', function(event){
				$('.mod-modal-content').html('');
				$('.mod-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
			$('.js-products_2023-modal-btn').on('click', function(event){
				var title = $(this).data('title');
				var imgs = $(this).data('imgs');
				var lead = $(this).data('lead');
				var link = $(this).data('link');
				$('.products_2023-modal-overlay').addClass(a);
				$('.products_2023-modal-content').html(
					'<div class="p40">' +
					'<h3 class="products_2023-modal-title">'+ title +'</h3>' +
					'<img src="/mens/rn-images/products/modal/'+ imgs +'_pc.jpg" class="width-u-100" data-view="pc tablet">' +
					'<img src="/mens/rn-images/products/modal/'+ imgs +'_sp.jpg" class="width-u-100" data-view="sp">' +
					'</div>'
				);
				scrollTopNow = $(window).scrollTop();
				$('body').addClass(f);
				$( '.container' ).css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				event.preventDefault();
			});
			$('.products_2023-modal-closeBtn').on('click', function(event){
				$('.products_2023-modal-content').html('');
				$('.products_2023-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
			$('.products_2023-modal-back').on('click', function(event){
				$('.products_2023-modal-content').html('');
				$('.products_2023-modal-overlay').removeClass(a);
				$('body').removeClass(f);
				$( '.container' ).css( {
					position: 'static',
				} );
				$(window).scrollTop(scrollTopNow);
				event.preventDefault();
			});
		},
		loadDelayScript(){
			var _this = this;
			_this.animation();
			_this.modal();
		}
	};

	$(() => FUNCTIONS.loadDelayScript());
})(window.jQuery, window.FUNCTIONS, window.cssua);
