(function($, FUNCTIONS, cssua){
	const clickEvent = cssua.ua.desktop ? 'click' : 'tap';
	const a = 'is-active';
	const v = 'is-visible';
	const slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		slides(){
			$('.index-slide01').slick({
				arrows: true,
				autoplay: true,
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: true,
				centerPadding: '15%',
				loop: true,
				infinite: true,
				dots: true,
				responsive: [
					{
					  breakpoint: 768,
					  settings: {
						slidesToShow: 1,
					    centerPadding: '10%'
					  }
					}
				]
			});
			$('.idx-slide02').slick({
				arrows: true,
				autoplay: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				centerMode: true,
				centerPadding: '20%',
				loop: true,
				infinite: true,
				dots: true,
				responsive: [
					{
					  breakpoint: 768,
					  settings: {
					    centerPadding: '10%'
					  }
					}
				]
			});
		},
		animation(){
			$(".js-fadeLeft").on("inview", function () {
				$(this).addClass("is-inview");
			});
		},
		setTicker() {
			$.ajax({
				url: 'https://www.shop-salon.jp/ajax/newsrelease/mens.json',
				dataType: "jsonp",
				jsonp: 'news_list',
				success: function (data) {
					var mensHtml = '';
					for (var i = 0; i < data.news_list[0].cf_ng_news.length; i++) {
						var itemdate = new Date(data.news_list[0].cf_ng_news[i].cf_n_date);
						var itemyear = itemdate.getFullYear();
						var itemmonth = itemdate.getMonth() + 1;
						var itemday = itemdate.getDate();
						mensHtml += '<ul class="index-news-content opacity"><li class="index-news-content-txt ff_barlow">NEWS</li><li class="index-news-content-day">'+itemyear+'/'+itemmonth+'/'+itemday+'<svg class="icon02 mod-iconRight"><use xlink:href="/mens/common/svg/sprite.svg#arrow_right2"></use></svg></li>';
						if(!data.news_list[0].cf_ng_news[i].cf_n_url) {
							mensHtml += '<li class="index-news-content-lead"><a href="https://www.aderans.co.jp/newsrelease/detail/'+data.news_list[0].cf_ng_news[i]._key+'.html" class="mod-a" target="_new">'+data.news_list[0].cf_ng_news[i].cf_n_title+'<svg class="icon02 mod-iconRight"><use xlink:href="/mens/common/svg/sprite.svg#arrow_right2"></use></svg></a></li></ul>';
						} else {
							mensHtml += '<li class="index-news-content-lead"><a href="'+data.news_list[0].cf_ng_news[i].cf_n_url+'" class="mod-a" target="_new">'+data.news_list[0].cf_ng_news[i].cf_n_title+'<svg class="icon02 mod-iconRight"><use xlink:href="/mens/common/svg/sprite.svg#arrow_right2"></use></svg></a></li></ul>';
						}
					}
					$('.js-ticker-mens').prepend(mensHtml);
					const $setElm = $('.js-ticker');
					$setElm.slick({
						autoplay: true,
						slidesToShow: 1,
						slidesToScroll: 1,
						autoplaySpeed: 4000,
						loop: true,
						speed: 1000,
						arrows: false,
						dots: false
					});
					$setElm.addClass(a);
				},
				error:function(err){
					console.log('mens2', err);
				},
			});
		},
		loadDelayScript(){
			var _this = this;
			_this.slides();
			_this.animation();
			_this.setTicker();
		}
	};

	$(() => FUNCTIONS.loadDelayScript());
})(window.jQuery, window.FUNCTIONS, window.cssua);